<template>
  <div class="userexpress-opening">
    <div class="userexpress-opening--container">
      <!-- Swiper Old opening hours -->
      <div
        class="userexpress-opening__container"
        :class="{ 'userexpress-opening__container--1-2': hasTmpOpeningHours }"
      >
        <span class="userexpress-opening__container__title">
          {{
            hasTmpOpeningHours
              ? $t('userexpress.id.general.campaign.opening.old.title')
              : $t('userexpress.id.general.campaign.opening.neutral')
          }}
        </span>
        <swiper class="userexpress-opening__container__swiper" ref="swiperOld" :options="swiperOptions">
          <swiper-slide
            class="userexpress-opening__container__swiper__slide"
            v-for="(slide, i) in parsedOpeningHours"
            :key="`slide-${i}`"
          >
            <div v-if="!slide.nodata" class="userexpress-opening__container__swiper__slide__title">
              {{
                slide.label
                  ? slide.label
                  : $tc('userexpress.id.general.campaign.opening.old.label', 2, {
                      position: slide.position,
                    })
              }}
            </div>
            <div v-if="slide.nodata" class="userexpress-opening__container__swiper__slide__slot">
              <div class="userexpress-opening__container__swiper__slide__slot__overlay">
                <div
                  class="userexpress-opening__container__swiper__slide__slot__overlay__box"
                  :class="`userexpress-opening__container__swiper__slide__slot__overlay__box--${slide.action}`"
                >
                  <div class="userexpress-opening__container__swiper__slide__slot__overlay__box__title">
                    {{ $t(`userexpress.id.general.campaign.action.${slide.action}`) }}
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="userexpress-opening__container__swiper__slide__slot"
              v-for="(slots, day, idx) in slide.hours"
              :key="`opening-hour-${idx}`"
            >
              <div class="userexpress-opening__container__swiper__slide__slot__day">
                {{ $t(`common.days.${day}`) }}
              </div>
              <div class="userexpress-opening__container__swiper__slide__slot__time">
                <div v-if="slots.length === 0">
                  {{ $t('common.label.closed') }}
                </div>
                <div
                  v-else-if="
                    slots.length === 1 && slots[0].split('-')[0] === '0:00' && slots[0].split('-')[1] === '0:00'
                  "
                >
                  {{ $t('common.label.h24') }}
                </div>
                <div v-else v-for="(slot, j) in slots" :key="`opening-hour-time-${j}`">
                  {{ slot.split('-')[0] }} - {{ slot.split('-')[1] }}
                </div>
              </div>
            </div>
          </swiper-slide>
          <div
            class="userexpress-opening__container__swiper__pagination swiper-pagination"
            slot="pagination"
            v-show="swiperOldPagination"
          ></div>
        </swiper>
      </div>

      <!-- Swiper New opening hours -->
      <div v-if="hasTmpOpeningHours" class="userexpress-opening__container userexpress-opening__container--1-2">
        <span class="userexpress-opening__container__title">
          {{ $t('userexpress.id.general.campaign.opening.new.title') }}
        </span>
        <swiper class="userexpress-opening__container__swiper" ref="swiperNew" :options="swiperOptions">
          <swiper-slide
            class="userexpress-opening__container__swiper__slide"
            v-for="(slide, i) in tmpOpeningHours"
            :key="`slide-${i}`"
          >
            <div v-if="slide.action !== 'delete'" class="userexpress-opening__container__swiper__slide__title">
              {{
                slide.label
                  ? slide.label
                  : $tc('userexpress.id.general.campaign.opening.new.label', 2, {
                      position: slide.position,
                    })
              }}
            </div>
            <div v-if="slide.action === 'delete'" class="userexpress-opening__container__swiper__slide__slot">
              <div class="userexpress-opening__container__swiper__slide__slot__overlay">
                <div
                  class="userexpress-opening__container__swiper__slide__slot__overlay__box"
                  :class="`userexpress-opening__container__swiper__slide__slot__overlay__box--${slide.action}`"
                >
                  <div class="userexpress-opening__container__swiper__slide__slot__overlay__box__title">
                    <span class="backoffice-icons">hours</span>
                    {{ $t(`userexpress.id.general.campaign.action.${slide.action}`) }}
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="userexpress-opening__container__swiper__slide__slot"
              :class="{
                'userexpress-opening__container__swiper__slide__slot--update': isDayUpdated(slots, day, slide.matchId),
              }"
              v-for="(slots, day, idx) in slide.hours"
              :key="`opening-hour-${idx}`"
            >
              <div class="userexpress-opening__container__swiper__slide__slot__day">
                {{ $t(`common.days.${day}`) }}
              </div>
              <div class="userexpress-opening__container__swiper__slide__slot__time">
                <div v-if="slots.length === 0">
                  {{ $t('common.label.closed') }}
                </div>
                <div
                  v-else-if="
                    slots.length === 1 && slots[0].split('-')[0] === '0:00' && slots[0].split('-')[1] === '0:00'
                  "
                >
                  {{ $t('common.label.h24') }}
                </div>
                <div v-else v-for="(slot, j) in slots" :key="`opening-hour-time-${j}`">
                  {{ slot.split('-')[0] }} - {{ slot.split('-')[1] }}
                </div>
              </div>
            </div>
          </swiper-slide>
          <div
            class="userexpress-opening__container__swiper__pagination swiper-pagination"
            slot="pagination"
            v-show="swiperNewPagination"
          ></div>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import isEqual from 'lodash/isEqual'
import 'swiper/css/swiper.css'

export default {
  name: 'UserExpressOpening',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    openingHours: {
      type: Array,
      required: true,
    },
    tmpOpeningHours: {
      type: Array,
      required: true,
    },
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      swiperOptions: {
        spaceBetween: 16,
        slidesPerView: 1,
        loop: false,
        simulateTouch: false,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
      swiperOldPagination: false,
      swiperNewPagination: false,
    }
  },
  mounted() {
    this.swiperOld?.pagination ? (this.swiperOld.controller.control = this.swiperNew) : null
    this.swiperNew?.pagination ? (this.swiperNew.controller.control = this.swiperOld) : null
    this.swiperOld?.on('paginationUpdate', this.checkPagination)
    this.swiperNew?.on('paginationUpdate', this.checkPagination)
    this.checkPagination()
  },
  computed: {
    swiperOld() {
      return this.$refs.swiperOld?.$swiper
    },
    swiperNew() {
      return this.$refs.swiperNew?.$swiper
    },
    hasOpeningHours() {
      return this.openingHours.length > 0
    },
    hasTmpOpeningHours() {
      return this.tmpOpeningHours.length > 0
    },
    parsedOpeningHours() {
      let openingHoursParsed = []

      if (this.hasTmpOpeningHours) {
        this.tmpOpeningHours.forEach(o1 => {
          if (o1.action === 'insert') {
            openingHoursParsed.push({ nodata: true, action: o1.action })
          } else {
            this.openingHours.forEach(o2 => {
              if (o1.matchId === o2.id) {
                openingHoursParsed.push(o2)
              }
            })
          }
        })
      } else {
        openingHoursParsed = this.openingHours
      }

      return openingHoursParsed
    },
  },
  methods: {
    checkPagination() {
      this.swiperOldPagination = this.swiperOld?.pagination.bullets.length > 1
      this.swiperNewPagination = this.swiperNew?.pagination.bullets.length > 1
    },
    isDayUpdated(hour, day, matchId) {
      let result = false
      this.openingHours.forEach(openingHour => {
        if (openingHour.id === matchId) {
          if (!isEqual(openingHour.hours[day], hour)) {
            result = true
          }
        }
      })
      return result
    },
  },
}
</script>

<style lang="scss" scoped>
.userexpress-opening {
  flex: 1 1 auto;
  overflow-y: auto;

  &--container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: $gutter-mobile;

    @media (min-width: $screen-sm) {
      flex-direction: row;
    }
  }

  &__container {
    flex: 0 0 100%;
    padding: $gutter-mobile;

    @media (min-width: $screen-md) {
      width: calc(100% / 2 - #{$gutter-tablet});
    }

    @media (min-width: $screen-xl) {
      width: calc(100% / 2 - #{$gutter-desktop});
    }

    &--1-2 {
      flex: 0 0 50%;
    }

    &__title {
      font-size: $font-size-md;
      font-weight: $font-weight-title;
    }
    &__swiper {
      padding: $gutter-mobile 0;
      height: 100%;

      &__slide {
        min-height: 200px;

        &__title {
          margin-bottom: $gutter-mobile / 2;
          font-size: $font-size-md;
        }
        &__slot {
          display: flex;
          justify-content: space-between;
          margin-bottom: $gutter-mobile / 2;
          color: $grey-french;

          &--update {
            color: map-get($generic-color-variants, 'warning');
          }

          &:last-child {
            margin-bottom: 0;
          }

          &__day {
            font-weight: 500;
          }

          &__time {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
          }

          &__overlay {
            display: flex;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            align-items: center;
            justify-content: center;
            z-index: 100;
            border-radius: $radius-default;
            background-color: rgba(0, 0, 0, 0.1);

            &__box {
              border-radius: $radius-default;
              padding: $gutter-mobile / 2 $gutter-mobile;

              &--insert {
                background-color: map-get($generic-color-variants, 'success');
              }
              &--delete {
                background-color: map-get($generic-color-variants, 'error');
              }

              &__title {
                display: flex;
                gap: 5px;
                align-items: center;
                text-align: center;
                color: $white;
              }
            }
          }
        }
      }
      &__pagination {
        bottom: 0;
      }
    }
  }
}
</style>

<!-- Swiper style -->
<style lang="scss">
.userexpress-opening {
  .swiper-pagination {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &-bullet {
      opacity: 0.5;
      outline: 0;
      background-color: $swiper-pagination-color-bg;
      width: $swiper-pagination-width;
      height: $swiper-pagination-height;

      &-active {
        opacity: 1;
        background-color: $swiper-pagination-color-bg-active;
        width: $swiper-pagination-width;
        height: $swiper-pagination-height;
      }
    }
  }
}
</style>
