<template>
  <div class="campaign-id">
    <screen-header
      class="campaign-id__header"
      :breadcrumbs="breadcrumbs"
      :is-loading="isLoading"
      :right-content="true"
      @click="closeLocation()"
      v-if="!hasError"
    >
      <template slot="title">
        <div class="campaign-id__header__title" @click="goToSelection">
          <template v-if="isMobile && !isEmpty(currentLocation)">
            <span class="backoffice-icons">previous</span>

            <ui-title
              :title="
                $t('userexpress.id.general.campaign.title.details', {
                  name: `${currentUserExpress.firstName} ${currentUserExpress.lastName}`,
                  location: `${currentLocation.name}`,
                })
              "
            />
          </template>
          <template v-else>
            <ui-title
              :title="
                $t('userexpress.id.general.campaign.title.general', {
                  name: `${currentUserExpress.firstName} ${currentUserExpress.lastName}`,
                })
              "
              @click="goToSelection"
            />
          </template>
        </div>
      </template>
      <skeleton-input v-if="isLoading" />
      <ui-button
        class="campaign-id__header__modify"
        button="cta"
        variant="data"
        icon="edit"
        :label="$t('userexpress.id.general.campaign.button.modify')"
        :mobile-toggle="true"
        :disabled="isEmpty(currentLocation)"
        @click="goToLocation"
        v-else
      />
    </screen-header>
    <screen-error v-if="hasError" :route="{ name: 'UserExpress' }" :label="$t('userexpress.id.error.button.back')" />
    <screen-container :full-height="true" direction="column" v-else>
      <!-- General Section -->
      <campaign-id-general
        :is-loading="isLoading"
        :is-updating="isUpdating"
        :is-opening-loading="isOpeningLoading"
        :current-campaign="currentCampaign"
        :current-user-express="currentUserExpress"
        :campaign-history="campaignHistory"
        :opening-hours="openingHours"
        :tmp-opening-hours="tmpOpeningHours"
        :exceptional-opening-hours="exceptionalOpeningHours"
        :tmp-exceptional-opening-hours="tmpExceptionalOpeningHours"
        :current-location="currentLocation"
        @locationClicked="openLocation"
        @campaignSubmit="campaignSubmit"
        @editSimpleField="editSimpleField"
      />
    </screen-container>
    <modal-simple-field
      v-if="isEditing && editingModal === 'field'"
      :title="modalTitle"
      :is-updating="isUpdating"
      :data="modal.data"
      :label="modal.label"
      :obj-key="modal.objKey"
      :parameters="modal.parameters"
      :description="$t('userexpress.id.modal.reject_description')"
      @save="saveSimpleField"
      @closed="closedModal"
    />
  </div>
</template>

<script>
import { isMobile } from '@/utils/navigator.util'
import { mapState, mapActions } from 'vuex'
import { notif } from '@/mixins/notification.mixin'
import SkeletonInput from '@/components/Skeleton/Input.vue'
import ScreenHeader from '@/components/Screen/Header.vue'
import ScreenContainer from '@/components/Screen/Container.vue'
import ScreenError from '@/components/Screen/Error.vue'
import UiButton from '@/components/UI/Button.vue'
import UiTitle from '@/components/UI/Title.vue'
import CampaignIdGeneral from '@/components/CampaignId/General.vue'
import ModalSimpleField from '@/components/Modal/SimpleField.vue'

export default {
  name: 'UserExpressIdCampaign',
  components: {
    SkeletonInput,
    ScreenHeader,
    ScreenContainer,
    ScreenError,
    UiButton,
    UiTitle,
    CampaignIdGeneral,
    ModalSimpleField,
  },
  mixins: [notif],
  data() {
    return {
      isLoading: false,
      isUpdating: false,
      isOpeningLoading: false,
      isEditing: false,
      isMobile: isMobile(),
      hasError: false,
      currentLocation: {},
      currentLocationHistory: false,
      editingModal: '',
      modal: {
        objKey: null,
        data: null,
        label: null,
        parameters: {
          type: 'text',
          mode: 'input',
          required: false,
          number: null,
        },
      },
    }
  },
  async created() {
    this.isLoading = true
    try {
      await this.getCurrentCampaign({ campaignId: this.$route.params.id })
      await this.getCurrentUserExpress({ userExpressId: this.currentCampaign.userExpressId })
      this.currentUserExpress.locations.forEach(location => {
        this.getCampaignHistory({ locationId: location.id, campaignId: this.$route.params.id })
      })
    } catch (err) {
      this.$log.error(err)
      this.hasError = true
    } finally {
      this.isLoading = false
    }
  },
  computed: {
    ...mapState({
      currentCampaign: state => state.userexpress.currentCampaign,
      campaignHistory: state => state.userexpress.campaignHistory,
      currentUserExpress: state => state.userexpress.currentUserExpress,
      tmpOpeningHours: state => state.userexpress.tmpLocationOpeningHours,
      tmpExceptionalOpeningHours: state => state.userexpress.tmpLocationExceptionalOpeningHours,
      openingHours: state => state.location.currentLocationOpeningHours,
      exceptionalOpeningHours: state => state.location.currentLocationExceptionalOpeningHours,
    }),
    breadcrumbs() {
      return [
        {
          label: this.$t('userexpress.list.breadcrumb'),
          route: {
            name: 'UserExpress',
            params: { currentTab: 'campaign' },
          },
        },
        {
          label: `${this.currentUserExpress.firstName} ${this.currentUserExpress.lastName}`,
          route: {
            name: 'CampaignId',
            params: {
              id: this.currentUserExpress.id,
              clientId: this.currentUserExpress.clientId,
            },
          },
        },
      ]
    },
    modalTitle() {
      return this.$t('userexpress.id.modal.comment_title')
    },
  },
  methods: {
    ...mapActions({
      getCurrentCampaign: 'userexpress/getCurrentCampaign',
      getCampaignHistory: 'userexpress/getCampaignHistory',
      getCurrentUserExpress: 'userexpress/getCurrentUserExpress',
      getTmpLocationOpeningHours: 'userexpress/getTmpLocationOpeningHours',
      getTmpLocationExceptionalOpeningHours: 'userexpress/getTmpLocationExceptionalOpeningHours',
      getOpeningHour: 'location/getOpeningHour',
      getExceptionalOpeningHour: 'location/getExceptionalOpeningHour',
      submitCampaign: 'userexpress/submitCampaign',
    }),
    async openLocation(e) {
      this.currentLocation = e.location
      this.isOpeningLoading = true
      await this.getTmpLocationOpeningHours(this.currentLocation.id)
      await this.getOpeningHour(this.currentLocation.id)

      await this.getTmpLocationExceptionalOpeningHours(this.currentLocation.id)
      await this.getExceptionalOpeningHour({ period: 'all', locationId: this.currentLocation.id })
      this.isOpeningLoading = false
    },
    closeLocation() {
      this.currentLocation = {}
    },
    goToLocation() {
      this.$router.push({ name: 'Location', params: { id: this.currentLocation.id } })
    },
    saveSimpleField(e) {
      this.campaignSubmit({ action: 'cancel', comment: e.objKey.comment, history: this.currentLocationHistory })
      this.currentLocationHistory = false
    },
    async campaignSubmit(e) {
      this.isUpdating = true
      try {
        await this.submitCampaign({ action: e.action, comment: e.comment, history: e.history })
        this.notificationSuccess('campaign', `${this.currentUserExpress.firstName} ${this.currentUserExpress.lastName}`)
      } catch (err) {
        this.$log.error(err)
        this.notificationError()
      } finally {
        this.$modal.hide('[modal-simple-field]')
        this.closedModal()
      }
    },
    editSimpleField(payload) {
      this.currentLocationHistory = payload.history
      this.modal.objKey = payload.objKey
      this.modal.label = this.$t(`userexpress.id.modal.${payload.objKey}`)
      this.modal.parameters = payload.parameters
      this.isEditing = true
      this.editingModal = 'field'
      this.$nextTick(() => {
        this.$modal.show('modal-simple-field')
      })
    },
    closedModal() {
      this.isEditing = false
      this.isUpdating = false
      this.editingModal = ''
      this.currentLocationHistory = false
    },
    goToSelection() {
      this.currentLocation = {}
    },
    isEmpty(obj) {
      return Object.keys(obj).length === 0
    },
  },
}
</script>

<style lang="scss">
.campaign-id {
  padding-bottom: $button-min-height + $gutter-mobile * 2;

  @media (min-width: $screen-sm) {
    padding-bottom: 0;
  }

  &__header {
    &__title {
      display: flex;
      gap: $gutter-mobile / 2;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      font-size: $font-size-lg;
      font-weight: $font-weight-title;
    }
  }
}
</style>
