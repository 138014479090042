<template>
  <div class="userexpress-exceptional-opening">
    <div class="userexpress-exceptional-opening--container">
      <!-- Old exceptional opening hours -->
      <div
        class="userexpress-exceptional-opening__container"
        :class="{ 'userexpress-exceptional-opening__container--1-2': hasTmpExceptionalOpeningHours }"
      >
        <span class="userexpress-exceptional-opening__container__title">
          {{
            hasTmpExceptionalOpeningHours
              ? $t('userexpress.id.general.campaign.exceptional_opening.old.title')
              : $t('userexpress.id.general.campaign.exceptional_opening.neutral')
          }}
        </span>
        <div
          v-for="(exceptionalHour, idx) in parsedExceptionalOpeningHours"
          :key="`exceptional-hour-${idx}`"
          class="userexpress-exceptional-opening__container__hours"
        >
          <div v-if="!exceptionalHour.nodata" class="userexpress-exceptional-opening__container__hours__title">
            {{
              exceptionalHour.label
                ? exceptionalHour.label
                : $tc('userexpress.id.general.campaign.exceptional_opening.old.label', 2, {
                    position: exceptionalHour.position,
                  })
            }}
          </div>
          <div v-if="exceptionalHour.nodata" class="userexpress-exceptional-opening__container__hours__slot">
            <div class="userexpress-exceptional-opening__container__hours__slot__overlay">
              <div
                class="userexpress-exceptional-opening__container__hours__slot__overlay__box"
                :class="`userexpress-exceptional-opening__container__hours__slot__overlay__box--${exceptionalHour.action}`"
              >
                <div class="userexpress-exceptional-opening__container__hours__slot__overlay__box__title">
                  {{ $t(`userexpress.id.general.campaign.action.${exceptionalHour.action}`) }}
                </div>
              </div>
            </div>
          </div>
          <div v-else class="userexpress-exceptional-opening__container__hours__slot">
            <div class="userexpress-exceptional-opening__container__hours__slot__day">
              <template
                v-if="
                  exceptionalHour.dateFrom === exceptionalHour.dateTo ||
                  (exceptionalHour.dateFrom && !exceptionalHour.dateTo)
                "
              >
                {{ $t('common.date.on') }} {{ date(exceptionalHour.dateFrom) }}
              </template>
              <template v-else>
                {{ $t('common.date.from') }} {{ date(exceptionalHour.dateFrom) }} {{ $t('common.date.to') }}
                {{ date(exceptionalHour.dateTo) }}
              </template>
            </div>
            <div class="userexpress-exceptional-opening__container__hours__slot__time">
              <div v-if="!exceptionalHour.openStatus">
                {{ $t('common.label.closed') }}
              </div>
              <div v-else-if="!exceptionalHour.hours1 && !exceptionalHour.hours2">
                {{ $t('common.label.open') }}
              </div>
              <template v-else>
                <div v-if="exceptionalHour.hours1">
                  {{ exceptionalHour.hours1.split('-')[0] }} - {{ exceptionalHour.hours1.split('-')[1] }}
                </div>
                <div v-if="exceptionalHour.hours2">
                  {{ exceptionalHour.hours2.split('-')[0] }} - {{ exceptionalHour.hours2.split('-')[1] }}
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>

      <!-- New exceptional opening hours -->
      <div
        v-if="hasTmpExceptionalOpeningHours"
        class="userexpress-exceptional-opening__container userexpress-exceptional-opening__container--1-2"
      >
        <span class="userexpress-exceptional-opening__container__title">
          {{ $t('userexpress.id.general.campaign.exceptional_opening.new.title') }}
        </span>
        <div
          v-for="(exceptionalHour, idx) in tmpExceptionalOpeningHours"
          :key="`exceptional-hour-${idx}`"
          class="userexpress-exceptional-opening__container__hours"
        >
          <div
            v-if="exceptionalHour.action !== 'delete'"
            class="userexpress-exceptional-opening__container__hours__title"
            :class="{
              'userexpress-exceptional-opening__container__hours__title--update': exceptionalHour.action === 'update',
            }"
          >
            {{
              exceptionalHour.label
                ? exceptionalHour.label
                : $tc('userexpress.id.general.campaign.exceptional_opening.new.label', 2, {
                    position: exceptionalHour.position,
                  })
            }}
          </div>
          <div
            v-if="exceptionalHour.action === 'delete'"
            class="userexpress-exceptional-opening__container__hours__slot"
          >
            <div class="userexpress-exceptional-opening__container__hours__slot__overlay">
              <div
                class="userexpress-exceptional-opening__container__hours__slot__overlay__box"
                :class="`userexpress-exceptional-opening__container__hours__slot__overlay__box--${exceptionalHour.action}`"
              >
                <div class="userexpress-exceptional-opening__container__hours__slot__overlay__box__title">
                  {{ $t(`userexpress.id.general.campaign.action.${exceptionalHour.action}`) }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            class="userexpress-exceptional-opening__container__hours__slot"
            :class="{
              'userexpress-exceptional-opening__container__hours__slot--update': exceptionalHour.action === 'update',
            }"
          >
            <div class="userexpress-exceptional-opening__container__hours__slot__day">
              <template
                v-if="
                  exceptionalHour.dateFrom === exceptionalHour.dateTo ||
                  (exceptionalHour.dateFrom && !exceptionalHour.dateTo)
                "
              >
                {{ $t('common.date.on') }} {{ date(exceptionalHour.dateFrom) }}
              </template>
              <template v-else>
                {{ $t('common.date.from') }} {{ date(exceptionalHour.dateFrom) }} {{ $t('common.date.to') }}
                {{ date(exceptionalHour.dateTo) }}
              </template>
            </div>
            <div class="userexpress-exceptional-opening__container__hours__slot__time">
              <div v-if="!exceptionalHour.openStatus">
                {{ $t('common.label.closed') }}
              </div>
              <div v-else-if="!exceptionalHour.hours1 && !exceptionalHour.hours2">
                {{ $t('common.label.open') }}
              </div>
              <template v-else>
                <div v-if="exceptionalHour.hours1">
                  {{ exceptionalHour.hours1.split('-')[0] }} - {{ exceptionalHour.hours1.split('-')[1] }}
                </div>
                <div v-if="exceptionalHour.hours2">
                  {{ exceptionalHour.hours2.split('-')[0] }} - {{ exceptionalHour.hours2.split('-')[1] }}
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatedDate } from '@/utils/date.util'

export default {
  name: 'UserExpressExceptionalOpening',
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    exceptionalOpeningHours: {
      type: Array,
      required: true,
    },
    tmpExceptionalOpeningHours: {
      type: Array,
      required: true,
    },
  },
  computed: {
    hasTmpExceptionalOpeningHours() {
      return this.tmpExceptionalOpeningHours.length > 0
    },
    parsedExceptionalOpeningHours() {
      let exceptionalOpeningHoursParsed = []

      if (this.hasTmpExceptionalOpeningHours) {
        this.tmpExceptionalOpeningHours.forEach(o1 => {
          if (o1.action === 'insert') {
            exceptionalOpeningHoursParsed.push({ nodata: true, action: o1.action })
          } else {
            this.exceptionalOpeningHours.forEach(o2 => {
              if (o1.matchId === o2.id) {
                exceptionalOpeningHoursParsed.push(o2)
              }
            })
          }
        })
      } else {
        exceptionalOpeningHoursParsed = this.exceptionalOpeningHours
      }
      return exceptionalOpeningHoursParsed
    },
  },
  methods: {
    date(date) {
      return formatedDate(date, 'DD/MM/YYYY', this.$i18n.locale)
    },
  },
}
</script>

<style lang="scss" scoped>
.userexpress-exceptional-opening {
  flex: 1 1 auto;
  overflow-y: auto;

  &--container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: $gutter-mobile;

    @media (min-width: $screen-sm) {
      flex-direction: row;
    }
  }

  &__container {
    flex: 0 0 100%;
    padding: $gutter-mobile;

    @media (min-width: $screen-md) {
      width: calc(100% / 2 - #{$gutter-tablet});
    }

    @media (min-width: $screen-xl) {
      width: calc(100% / 2 - #{$gutter-desktop});
    }

    &--1-2 {
      flex: 0 0 50%;
    }

    &__title {
      font-size: $font-size-md;
      font-weight: $font-weight-title;
    }
    &__hours {
      padding: $gutter-mobile / 2 0;
      min-height: 82px;

      &__title {
        font-size: $font-size-md;

        &--update {
          color: map-get($generic-color-variants, 'warning');
        }
      }

      &__slot {
        display: flex;
        position: relative;
        justify-content: space-between;
        margin-bottom: $gutter-mobile / 2;
        color: $grey-french;

        &--update {
          color: map-get($generic-color-variants, 'warning');
        }

        &:last-child {
          margin-bottom: 0;
        }

        &__day {
          font-weight: 500;
        }

        &__time {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }

        &__overlay {
          display: flex;
          position: relative;
          align-items: center;
          justify-content: center;
          z-index: 100;
          border-radius: $radius-default;
          background-color: rgba(0, 0, 0, 0.1);
          padding: $gutter-mobile 0;
          width: 100%;

          &__box {
            border-radius: $radius-default;
            padding: $gutter-mobile / 2 $gutter-mobile;

            &--insert {
              background-color: map-get($generic-color-variants, 'success');
            }
            &--delete {
              background-color: map-get($generic-color-variants, 'error');
            }

            &__title {
              display: flex;
              gap: 5px;
              align-items: center;
              text-align: center;
              color: $white;
            }
          }
        }
      }
    }
  }
}
</style>
