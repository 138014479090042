<template>
  <div class="campaignid-general--container">
    <screen-grid class="campaignid-general" :full-height="true">
      <screen-card
        display="flex"
        :no-padding="true"
        :overflow-hidden="true"
        :full-height="true"
        :is-loading="isLoading"
      >
        <template v-slot:body>
          <div class="campaignid-general__selection" v-if="displaySelection">
            <div class="campaignid-general__selection__header">
              <div class="campaignid-general__selection__header__title">
                <skeleton-icon v-if="isLoading" />
                <span class="backoffice-icons" v-else>user</span>
                <div class="campaignid-general__selection__header__title__user">
                  <skeleton-line v-if="isLoading" height="24px" width="225px" />
                  <span class="campaignid-general__selection__header__title__user__name" v-else>
                    {{ `${currentUserExpress.firstName} ${currentUserExpress.lastName}` }}
                  </span>
                  <skeleton-line v-if="isLoading" height="16px" width="175px" margin-top="2px" />
                  <span class="campaignid-general__selection__header__title__user__email" v-else>
                    {{ currentUserExpress.email }}
                  </span>
                </div>
              </div>
              <!-- <div class="campaignid-general__selection__header__search">
                <skeleton-input v-if="isLoading" width="100%" />
                <ui-input
                  class="campaignid-general__selection__header__search__input"
                  v-model="searchQuery"
                  id="search"
                  :label="$t('userexpress.id.general.campaign.search')"
                  icon="search"
                  design="minimalist"
                  v-else
                />
              </div>
              <div class="campaignid-general__selection__header__filters">
                <skeleton-line v-if="isLoading" height="25px" width="60px" margin-right="5px" />
                <skeleton-line v-if="isLoading" height="25px" width="60px" margin-right="5px" />
                <skeleton-line v-if="isLoading" height="25px" width="60px" margin-right="5px" />
                <span
                  class="campaignid-general__selection__header__filters__action"
                  :class="`campaignid-general__selection__header__filters__action--${filter.key}`"
                  v-for="filter in filters"
                  :key="filter.id"
                  :selected="isFilterSelected(filter.key)"
                  @click="toggleFilterSelected(filter.key)"
                  v-else
                >
                  {{ filter.label }}
                </span>
                <skeleton-line v-if="isLoading" height="25px" width="60px" />
                <span
                  class="campaignid-general__selection__header__filters__action"
                  :class="`campaignid-general__selection__header__filters__action--all`"
                  :selected="isAllFilterSelected"
                  @click="toggleAllFilterSelected"
                  v-else
                >
                  {{ $t('userexpress.id.general.campaign.status.all') }}
                </span>
              </div> -->
            </div>
            <div class="campaignid-general__selection__locations">
              <ui-table-loader v-if="isLoading" :is-loading="isLoading" />
              <div class="campaignid-general__selection__locations__box" v-else>
                <template v-for="(location, idx) in currentUserExpress.locations">
                  <div
                    class="campaignid-general__selection__locations__box__item"
                    :class="{
                      'campaignid-general__selection__locations__box__item--selected': location === currentLocation,
                    }"
                    @click="locationClicked(location)"
                    :key="idx"
                    v-if="getHistory(location.id)"
                  >
                    <div class="campaignid-general__selection__locations__box__item__info">
                      <span class="campaignid-general__selection__locations__box__item__info__name">
                        {{ location.name }}
                      </span>
                      <span class="campaignid-general__selection__locations__box__item__info__address">
                        {{ location.postalCode }} {{ location.city }}
                      </span>
                    </div>
                    <div
                      class="campaignid-general__selection__locations__box__item__status"
                      :class="`campaignid-general__selection__locations__box__item__status--${
                        getHistory(location.id).status
                      }`"
                    >
                      {{ $t(`userexpress.id.general.campaign.status.${getHistory(location.id).status}`) }}
                    </div>
                  </div>
                  <div
                    class="
                      campaignid-general__selection__locations__box__item
                      campaignid-general__selection__locations__box__item--no-data
                    "
                    :class="`campaignid-general__selection__locations__box__item--no-data-${idx}`"
                    :key="idx"
                    v-else-if="!hasValidLocations"
                  >
                    <em v-html="$t('common.label.noData')" />
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="campaignid-general__review" v-if="!isEmpty(currentLocation)">
            <ui-table-loader v-if="isOpeningLoading" :is-loading="isOpeningLoading" />
            <template v-else>
              <screen-tabs
                class="campaignid-general__review__tabs"
                :tabs="tabs"
                :element-fixed="elementFixed"
                :current-tab="currentTab"
                :is-loading="isLoading"
                @mounted="onTabsMounted"
                @click="setCurrentTab"
              />

              <!-- Opening hours -->
              <template v-if="openingHours.length === 0 && tmpOpeningHours.length === 0">
                <div class="campaignid-general__review__no-opening-hours" v-if="tabSelected === 'opening'">
                  <span class="campaignid-general__review__no-opening-hours__icon backoffice-icons">hours</span>
                  <span class="campaignid-general__review__no-opening-hours__title">
                    {{ $t('userexpress.id.general.campaign.review.no_opening_hours') }}
                  </span>
                </div>
              </template>
              <template v-else>
                <user-express-opening
                  :opening-hours="openingHours"
                  :tmp-opening-hours="tmpOpeningHours"
                  :is-loading="isLoading"
                  v-if="tabSelected === 'opening'"
                />
              </template>

              <!-- Exceptional opening hours -->
              <template v-if="exceptionalOpeningHours.length === 0 && tmpExceptionalOpeningHours.length === 0">
                <div class="campaignid-general__review__no-opening-hours" v-if="tabSelected === 'exceptional_opening'">
                  <span class="campaignid-general__review__no-opening-hours__icon backoffice-icons">hours</span>
                  <span class="campaignid-general__review__no-opening-hours__title">
                    {{ $t('userexpress.id.general.campaign.review.no_exceptional_opening_hours') }}
                  </span>
                </div>
              </template>
              <template v-else>
                <user-express-exceptional-opening
                  :exceptional-opening-hours="exceptionalOpeningHours"
                  :tmp-exceptional-opening-hours="tmpExceptionalOpeningHours"
                  :is-loading="isLoading"
                  v-if="tabSelected === 'exceptional_opening'"
                />
              </template>

              <div class="campaignid-general__review__button">
                <skeleton-input v-if="isLoading" />
                <ui-button
                  class="campaign-general__review__button__refuse"
                  button="cta"
                  variant="error"
                  :label="$t('userexpress.id.general.campaign.button.refuse')"
                  :disabled="getHistory(currentLocation.id).status !== 'processing'"
                  @click="editSimpleField('comment', 'text', 'textarea', true)"
                  v-else
                />
                <skeleton-input v-if="isLoading" />
                <ui-button
                  class="campaign-general__review__button__accept"
                  button="cta"
                  variant="data"
                  :label="$t('userexpress.id.general.campaign.button.accept')"
                  :disabled="getHistory(currentLocation.id).status !== 'processing'"
                  @click="campaignSubmit('accept')"
                  v-else
                />
              </div>
            </template>
          </div>
          <div class="campaignid-general__unselected" v-else>
            <div class="campaignid-general__unselected__icon backoffice-icons">store</div>
            <div class="campaignid-general__unselected__title">
              {{ $t('userexpress.id.general.campaign.review.select') }}
            </div>
          </div>
        </template>
      </screen-card>
    </screen-grid>
  </div>
</template>

<script>
import ScreenCard from '@/components/Screen/Card.vue'
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenTabs from '@/components/Screen/Tabs.vue'
import UiButton from '@/components/UI/Button.vue'
// import UiInput from '@/components/UI/Input.vue'
import UiTableLoader from '@/components/UI/TableLoader.vue'
import UserExpressOpening from '@/components/CampaignId/Opening.vue'
import UserExpressExceptionalOpening from '@/components/CampaignId/ExceptionalOpening.vue'
import SkeletonIcon from '@/components/Skeleton/Icon.vue'
import SkeletonInput from '@/components/Skeleton/Input.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'
import { onScroll } from '@/mixins/scroll.mixin'
import { notif } from '@/mixins/notification.mixin'

export default {
  name: 'CampaignIdGeneral',
  components: {
    ScreenCard,
    ScreenGrid,
    ScreenTabs,
    UiButton,
    // UiInput,
    UiTableLoader,
    UserExpressOpening,
    UserExpressExceptionalOpening,
    SkeletonIcon,
    SkeletonInput,
    SkeletonLine,
  },
  mixins: [onScroll, notif],
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isOpeningLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentCampaign: {
      type: Object,
      required: true,
    },
    currentUserExpress: {
      type: Object,
      required: true,
    },
    campaignHistory: {
      type: Array,
      required: true,
    },
    openingHours: {
      type: Array,
      required: true,
    },
    tmpOpeningHours: {
      type: Array,
      required: true,
    },
    exceptionalOpeningHours: {
      type: Array,
      required: true,
    },
    tmpExceptionalOpeningHours: {
      type: Array,
      required: true,
    },
    currentLocation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedFilters: [],
      searchQuery: '',
      tabsElement: '',
      tabSelected: 'opening',
    }
  },
  created() {
    this.filters.forEach(filter => {
      filter.default ? this.selectedFilters.push(filter.key) : null
    })
  },
  computed: {
    filters() {
      return [
        {
          label: this.$t('userexpress.id.general.campaign.status.processing'),
          id: '2',
          key: 'processing',
          default: true,
        },
        {
          label: this.$t('userexpress.id.general.campaign.status.validated'),
          id: '3',
          key: 'validated',
          default: false,
        },
        {
          label: this.$t('userexpress.id.general.campaign.status.rejected'),
          id: '4',
          key: 'rejected',
          default: false,
        },
      ]
    },
    tabs() {
      return [
        {
          label: this.$t('userexpress.id.general.campaign.tab.opening'),
          key: 'opening',
        },
        {
          label: this.$t('userexpress.id.general.campaign.tab.exceptional_opening'),
          key: 'exceptional_opening',
        },
      ]
    },
    currentTab() {
      return this.tabSelected
    },
    isAllFilterSelected() {
      return this.selectedFilters.length >= 3
    },
    hasValidLocations() {
      const locationHistoryList = this.currentUserExpress.locations.filter(location => {
        const history = this.getHistory(location.id)
        if (history !== null) return history
        return false
      })
      return locationHistoryList.length > 0 ? true : false
    },
    displaySelection() {
      if (this.isMobile) {
        if (!this.isEmpty(this.currentLocation)) {
          return false
        }
        return true
      }
      return true
    },
  },
  methods: {
    isFilterSelected(filterKey) {
      return this.selectedFilters.includes(filterKey)
    },
    toggleFilterSelected(filterKey) {
      if (this.selectedFilters.includes(filterKey)) {
        const index = this.selectedFilters.indexOf(filterKey)
        index > -1 ? this.selectedFilters.splice(index, 1) : null
      } else {
        this.selectedFilters.push(filterKey)
      }
    },
    toggleAllFilterSelected() {
      if (this.isAllFilterSelected) {
        this.selectedFilters = []
      } else {
        this.filters.forEach(filter => {
          if (!this.selectedFilters.includes(filter.key)) {
            this.selectedFilters.push(filter.key)
          }
        })
      }
    },
    getHistory(locationId) {
      let historySelected = null
      this.campaignHistory.forEach(history => {
        if (history?.locationId === locationId && history.userExpressUpdateCampaignId === this.currentCampaign.id) {
          historySelected = history
        }
      })
      return historySelected
    },
    locationClicked(location) {
      this.$emit('locationClicked', {
        location,
      })
      this.tabSelected = 'opening'
    },
    campaignSubmit(action) {
      this.$emit('campaignSubmit', {
        action,
        history: this.getHistory(this.currentLocation.id),
      })
    },
    onTabsMounted(element) {
      this.tabsElement = element
    },
    setCurrentTab(tab) {
      this.tabSelected = tab
    },
    editSimpleField(objKey, type, mode, required = false, number = null, options = [], dropdown = {}) {
      this.$emit('editSimpleField', {
        history: this.getHistory(this.currentLocation.id),
        objKey,
        parameters: {
          type,
          mode,
          required,
          number,
          options,
          dropdown,
        },
      })
    },
    isEmpty(obj) {
      return Object.keys(obj).length === 0
    },
  },
}
</script>

<style lang="scss" scoped>
.campaignid-general {
  padding-bottom: $button-min-height + $gutter-mobile * 2;

  @media (min-width: $screen-sm) {
    padding-bottom: 0;
  }

  &__selection {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    gap: $gutter-mobile;
    padding: $gutter-mobile;
    height: 100%;

    @media (min-width: $screen-sm) {
      flex: 0 0 30%;
      gap: $gutter-tablet;
      border-right: 2px solid var(--border-color);
      padding: $gutter-tablet;
    }

    @media (min-width: $screen-md) {
      gap: $gutter-desktop;
      padding: $gutter-desktop;
    }

    &__header {
      flex: 0 0 auto;

      &__title {
        display: flex;
        flex-direction: row;
        gap: 10px;

        &__user {
          display: flex;
          flex-direction: column;
          &__name {
            text-transform: capitalize;
            font-size: $font-size-md;
            font-weight: $font-weight-default;
          }
          &__email {
            color: map-get($generic-color-variants, 'inactive');
          }
        }
      }
      &__search {
        padding: $gutter-mobile 0;
      }
      &__filters {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;

        &__action {
          border: 1px solid;
          border-radius: 50px;
          border-color: $black-baltic-sea;
          cursor: pointer;
          padding: 4px 12px;
          white-space: nowrap;
          color: $black-baltic-sea;
          font-size: $font-size-sm;
          user-select: none;

          &[selected='selected'] {
            background-color: $black-baltic-sea;
            color: $white;
          }

          &--processing {
            border-color: map-get($generic-color-variants, 'warning');
            color: map-get($generic-color-variants, 'warning');

            &[selected='selected'] {
              background-color: map-get($generic-color-variants, 'warning');
            }
          }
          &--validated {
            border-color: map-get($generic-color-variants, 'success');
            color: map-get($generic-color-variants, 'success');

            &[selected='selected'] {
              background-color: map-get($generic-color-variants, 'success');
            }
          }
          &--rejected {
            border-color: map-get($generic-color-variants, 'error');
            color: map-get($generic-color-variants, 'error');

            &[selected='selected'] {
              background-color: map-get($generic-color-variants, 'error');
            }
          }
        }
      }
    }
    &__locations {
      position: relative;
      flex: 1 1 auto;
      margin: -$gutter-mobile;
      margin-top: 0 !important;
      overflow-y: auto;

      @media (min-width: $screen-sm) {
        margin: -$gutter-tablet;
      }

      @media (min-width: $screen-md) {
        margin: -$gutter-desktop;
      }

      &__box {
        width: 100%;

        &__item {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          transition: all $transition-duration-fast $transition-effect-default;
          border-top: 1px solid var(--border-color);
          padding: $gutter-mobile $gutter-mobile;

          @media (min-width: $screen-sm) {
            padding: $gutter-mobile $gutter-tablet;
          }

          @media (min-width: $screen-md) {
            padding: $gutter-mobile $gutter-desktop;
          }

          &--no-data {
            display: none;
            &-0 {
              display: block;
            }
          }

          &--selected {
            box-shadow: inset 4px 0 0 0 var(--product-color);
            background-color: var(--bg-color-hover);
            cursor: pointer;
          }

          &:hover {
            box-shadow: inset 4px 0 0 0 var(--product-color);
            background-color: var(--bg-color-hover);
            cursor: pointer;
          }

          &__info {
            display: flex;
            flex-direction: column;
            &__name {
              font-weight: $font-weight-title;
            }
          }
          &__status {
            border-radius: 50px;
            padding: 2px 8px;
            white-space: nowrap;
            color: $white;
            font-size: $font-size-sm;
            user-select: none;

            &--waiting {
              background-color: map-get($generic-color-variants, 'inactive');
            }
            &--processing {
              background-color: map-get($generic-color-variants, 'warning');
            }
            &--validated {
              background-color: map-get($generic-color-variants, 'success');
            }
            &--rejected {
              background-color: map-get($generic-color-variants, 'error');
            }
          }
        }
      }
    }
  }
  &__unselected {
    display: none;

    @media (min-width: $screen-sm) {
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    &__icon {
      font-size: 100px;
    }
    &__title {
      @include title;
    }
  }
  &__review {
    display: flex;
    position: relative;
    flex: 1 1 auto;
    flex-direction: column;
    width: 1px;

    &__tabs {
      flex: 0 0 auto;
    }
    &__button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      border-top: 1px solid var(--border-color);
      padding: 8px 0;
    }

    &__no-opening-hours {
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      gap: 4px;
      align-items: center;
      justify-content: center;
      padding: $gutter-mobile;

      &__icon {
        font-size: 50px;
      }
      &__title {
        @include title;

        text-align: center;
      }
    }
  }

  &--container {
    height: 100%;
  }
}
</style>

<style lang="scss">
.campaignid-general__review__tabs {
  .screen-tabs__wrapper__container {
    padding: 0 $gutter-mobile;

    @media (min-width: $screen-sm) {
      padding: 0;
    }
  }
}
</style>
