var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"userexpress-exceptional-opening"},[_c('div',{staticClass:"userexpress-exceptional-opening--container"},[_c('div',{staticClass:"userexpress-exceptional-opening__container",class:{ 'userexpress-exceptional-opening__container--1-2': _vm.hasTmpExceptionalOpeningHours }},[_c('span',{staticClass:"userexpress-exceptional-opening__container__title"},[_vm._v(" "+_vm._s(_vm.hasTmpExceptionalOpeningHours ? _vm.$t('userexpress.id.general.campaign.exceptional_opening.old.title') : _vm.$t('userexpress.id.general.campaign.exceptional_opening.neutral'))+" ")]),_vm._l((_vm.parsedExceptionalOpeningHours),function(exceptionalHour,idx){return _c('div',{key:("exceptional-hour-" + idx),staticClass:"userexpress-exceptional-opening__container__hours"},[(!exceptionalHour.nodata)?_c('div',{staticClass:"userexpress-exceptional-opening__container__hours__title"},[_vm._v(" "+_vm._s(exceptionalHour.label ? exceptionalHour.label : _vm.$tc('userexpress.id.general.campaign.exceptional_opening.old.label', 2, { position: exceptionalHour.position, }))+" ")]):_vm._e(),(exceptionalHour.nodata)?_c('div',{staticClass:"userexpress-exceptional-opening__container__hours__slot"},[_c('div',{staticClass:"userexpress-exceptional-opening__container__hours__slot__overlay"},[_c('div',{staticClass:"userexpress-exceptional-opening__container__hours__slot__overlay__box",class:("userexpress-exceptional-opening__container__hours__slot__overlay__box--" + (exceptionalHour.action))},[_c('div',{staticClass:"userexpress-exceptional-opening__container__hours__slot__overlay__box__title"},[_vm._v(" "+_vm._s(_vm.$t(("userexpress.id.general.campaign.action." + (exceptionalHour.action))))+" ")])])])]):_c('div',{staticClass:"userexpress-exceptional-opening__container__hours__slot"},[_c('div',{staticClass:"userexpress-exceptional-opening__container__hours__slot__day"},[(
                exceptionalHour.dateFrom === exceptionalHour.dateTo ||
                (exceptionalHour.dateFrom && !exceptionalHour.dateTo)
              )?[_vm._v(" "+_vm._s(_vm.$t('common.date.on'))+" "+_vm._s(_vm.date(exceptionalHour.dateFrom))+" ")]:[_vm._v(" "+_vm._s(_vm.$t('common.date.from'))+" "+_vm._s(_vm.date(exceptionalHour.dateFrom))+" "+_vm._s(_vm.$t('common.date.to'))+" "+_vm._s(_vm.date(exceptionalHour.dateTo))+" ")]],2),_c('div',{staticClass:"userexpress-exceptional-opening__container__hours__slot__time"},[(!exceptionalHour.openStatus)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('common.label.closed'))+" ")]):(!exceptionalHour.hours1 && !exceptionalHour.hours2)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('common.label.open'))+" ")]):[(exceptionalHour.hours1)?_c('div',[_vm._v(" "+_vm._s(exceptionalHour.hours1.split('-')[0])+" - "+_vm._s(exceptionalHour.hours1.split('-')[1])+" ")]):_vm._e(),(exceptionalHour.hours2)?_c('div',[_vm._v(" "+_vm._s(exceptionalHour.hours2.split('-')[0])+" - "+_vm._s(exceptionalHour.hours2.split('-')[1])+" ")]):_vm._e()]],2)])])})],2),(_vm.hasTmpExceptionalOpeningHours)?_c('div',{staticClass:"userexpress-exceptional-opening__container userexpress-exceptional-opening__container--1-2"},[_c('span',{staticClass:"userexpress-exceptional-opening__container__title"},[_vm._v(" "+_vm._s(_vm.$t('userexpress.id.general.campaign.exceptional_opening.new.title'))+" ")]),_vm._l((_vm.tmpExceptionalOpeningHours),function(exceptionalHour,idx){return _c('div',{key:("exceptional-hour-" + idx),staticClass:"userexpress-exceptional-opening__container__hours"},[(exceptionalHour.action !== 'delete')?_c('div',{staticClass:"userexpress-exceptional-opening__container__hours__title",class:{
            'userexpress-exceptional-opening__container__hours__title--update': exceptionalHour.action === 'update',
          }},[_vm._v(" "+_vm._s(exceptionalHour.label ? exceptionalHour.label : _vm.$tc('userexpress.id.general.campaign.exceptional_opening.new.label', 2, { position: exceptionalHour.position, }))+" ")]):_vm._e(),(exceptionalHour.action === 'delete')?_c('div',{staticClass:"userexpress-exceptional-opening__container__hours__slot"},[_c('div',{staticClass:"userexpress-exceptional-opening__container__hours__slot__overlay"},[_c('div',{staticClass:"userexpress-exceptional-opening__container__hours__slot__overlay__box",class:("userexpress-exceptional-opening__container__hours__slot__overlay__box--" + (exceptionalHour.action))},[_c('div',{staticClass:"userexpress-exceptional-opening__container__hours__slot__overlay__box__title"},[_vm._v(" "+_vm._s(_vm.$t(("userexpress.id.general.campaign.action." + (exceptionalHour.action))))+" ")])])])]):_c('div',{staticClass:"userexpress-exceptional-opening__container__hours__slot",class:{
            'userexpress-exceptional-opening__container__hours__slot--update': exceptionalHour.action === 'update',
          }},[_c('div',{staticClass:"userexpress-exceptional-opening__container__hours__slot__day"},[(
                exceptionalHour.dateFrom === exceptionalHour.dateTo ||
                (exceptionalHour.dateFrom && !exceptionalHour.dateTo)
              )?[_vm._v(" "+_vm._s(_vm.$t('common.date.on'))+" "+_vm._s(_vm.date(exceptionalHour.dateFrom))+" ")]:[_vm._v(" "+_vm._s(_vm.$t('common.date.from'))+" "+_vm._s(_vm.date(exceptionalHour.dateFrom))+" "+_vm._s(_vm.$t('common.date.to'))+" "+_vm._s(_vm.date(exceptionalHour.dateTo))+" ")]],2),_c('div',{staticClass:"userexpress-exceptional-opening__container__hours__slot__time"},[(!exceptionalHour.openStatus)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('common.label.closed'))+" ")]):(!exceptionalHour.hours1 && !exceptionalHour.hours2)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('common.label.open'))+" ")]):[(exceptionalHour.hours1)?_c('div',[_vm._v(" "+_vm._s(exceptionalHour.hours1.split('-')[0])+" - "+_vm._s(exceptionalHour.hours1.split('-')[1])+" ")]):_vm._e(),(exceptionalHour.hours2)?_c('div',[_vm._v(" "+_vm._s(exceptionalHour.hours2.split('-')[0])+" - "+_vm._s(exceptionalHour.hours2.split('-')[1])+" ")]):_vm._e()]],2)])])})],2):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }